<script setup>
import { useSettings } from "~/composables/useSettings";

const { settings } = useSettings();

const props = defineProps(["singleBible"]);

const chapterTitle = computed(() => {
  return props.singleBible?.location.value?.bookModel?.bookCode == "Psa"
    ? "Psalm"
    : "Chapter";
});
const bibleLocale = computed(() => {
  return props.singleBible.location.value?.bibleModel?.bibleLocale;
});

const singleVerse = computed(() => {
  return props.singleBible.location.value?.chaptersHtml?.[0]?.verses[0];
});

const singlePassage = computed(() => {
  return props.singleBible.location.value?.chaptersHtml?.[0]?.verses?.slice(
    0,
    5
  );
});

onMounted(() => {});
</script>
<template>
  <client-only>
    <div class="verses-wrapper" :class="settingsComputedClass">
      <div>
        <p class="chapter-heading">
          {{ transl(chapterTitle, bibleLocale) }}
          {{ singleVerse?.chapterNum }}
        </p>
        <span v-for="varseModel in singlePassage" class="chapter-verse">
          <span class="chapter-verse-num">{{ varseModel?.verseNum }}</span>
          <span
            v-html="varseModel?.verseText"
            :class="{ enableStrong: settings.enableStrong }"
          ></span>
        </span>
      </div>
    </div>
  </client-only>
</template>
